import React, { Suspense, lazy, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';

import ScrollManager from './components/config.jsx';

import './assests/css/style.scss';
import './assests/css/carousel.min.css';
import './assests/css/animations.scss';
import './assests/css/theme.scss';


import 'bootstrap/dist/css/bootstrap.min.css';


import './assests/js/num.js';

import 'aos/dist/aos.css';

import Home from './components/home.jsx';
import Preloader from './components/preloader.jsx';
import Header from './components/header.jsx';
import Footer from './components/footer.jsx';

const NewsRoom = lazy(() => import('./components/newsroom.jsx'));
const Overview = lazy(() => import('./components/company-oveerview.jsx'));
const Careers = lazy(() => import('./components/carrers.jsx'));
const Notfound = lazy(() => import('./components/notfound.jsx'));
const Privacy = lazy(() => import('./components/privacy.jsx'));
const Terms = lazy(() => import('./components/terms.jsx'));
const Contact = lazy(() => import('./components/contact-us.jsx'));
const Success = lazy(() => import('./components/success.jsx'));
const Error = lazy(() => import('./components/error.jsx'));

const News1 = lazy(() => import('./components/news/1.jsx'));
const News2 = lazy(() => import('./components/news/2.jsx'));
const News3 = lazy(() => import('./components/news/3.jsx'));
const News4 = lazy(() => import('./components/news/4.jsx'));
const News5 = lazy(() => import('./components/news/5.jsx'));


function App() {

  const RedirectWithLoader = () => {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
  
    useEffect(() => {
      setTimeout(() => {
        navigate("/en-PK/");
      }, 1000); 
    }, [navigate]);
  
    return loading ? <Preloader /> : null;
  };

  useEffect(() => {
    import('./components/home.jsx');
    import('./components/newsroom.jsx');
  }, []);

  return (
    <Router>
      <Header />
      <ScrollManager />
      <Suspense fallback={<Preloader />}>
        <Routes>
          <Route path="/" element={<RedirectWithLoader />} />
          <Route path="/en-PK/" element={<Home />} />
          <Route path="/source/" element={<NewsRoom />} />
          <Route path="/en/company-overview/" element={<Overview />} />
          <Route path="/contactus/" element={<Contact />} />
          <Route path="/careers/" element={<Careers />} />
          <Route path="/en/privacystatement/" element={<Privacy />} />
          <Route path="/en/legal/terms-of-use/" element={<Terms />} />

          <Route path="/source/topics/company-news=Haantlay-Secures-2nd-Position-at-IEEE-Expo" element={<News1 />} />
          <Route path="/source/topics/company-news=A-Triumph-in-Speed-Coding-at-the-WEC-Olympiad" element={<News2 />} />
          <Route path="/source/topics/company-news=High-Achiever-Award" element={<News3 />} />
          <Route path="/source/topics/company-news=University-of-Chakwal-renames-BS-Block-to-Mehar-Hasnat-Block" element={<News4 />} />
          <Route path="/source/topics/company-news=A-Remarkable-Achievement-by-Haantlay's-Chairman" element={<News5 />} />

          <Route path="/feedback/success" element={<Success />} />
          <Route path="/feedback/error" element={<Error />} />


          <Route path="*" element={<Notfound />} />
        </Routes>
      </Suspense>
      <Footer />
    </Router>
  );
}

export default App;