import React, { useState, useEffect } from 'react';
import { IoIosClose } from "react-icons/io";
import { IoMenuOutline } from "react-icons/io5";

import { CiMenuFries } from "react-icons/ci";
import { RiMenu3Fill } from "react-icons/ri";
import { CgMenuRight } from "react-icons/cg";
import { GoPerson } from "react-icons/go";
import { BsArrowRight } from "react-icons/bs";
import {HashLink} from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import logo1 from '../assests/img/logo2.svg';
import { GrAccessibility } from "react-icons/gr";
import { IoMdClose } from "react-icons/io";
import logo2 from '../assests/img/logo1.svg';



const logoimage = document.querySelector('#logoimage');


function Header() {
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [number, setNumber] = useState(1);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [toggleIconColor, setToggleIconColor] = useState('white');
  const [logoSrc, setLogoSrc] = useState(logo1);


  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      if (scrollTop > lastScrollTop) {
        const header = document.getElementById('header');
        if (header) header.style.top = '-55px';
        const pbar = document.querySelector('.p-bar');
        if (pbar) pbar.style.backgroundColor = "#333";
      
        setLogoSrc(logo2);
        const bocs = document.querySelector('#t-b-n');
        bocs.style.borderColor = "rgba(205,205,205,0.22)";
      

      } else {
        const header = document.getElementById('header');
        if (header) header.style.top = '0';
        document.querySelectorAll('.ch-li, .ch-li1, .ch-li2, .ch-li3, #t-b-n').forEach(item => {

          setNumber(2);
          item.style.color = '#262626';
      
          header.style.backgroundColor = "white";
          item.addEventListener('mouseenter', function() {
              this.style.borderColor = '#262626';
          });
      
          item.addEventListener('mouseleave', function() {
              this.style.borderColor = ''; 
          });
        });
        const bbButton = document.querySelector('#nav-btn');
        if (bbButton) bbButton.style.display = 'block';
        const pbar = document.querySelector('.p-bar');
        if (pbar) pbar.style.backgroundColor = '#333';
      }
      setLastScrollTop(scrollTop);
    };

    const handleProgress = () => {
      const scrollPos = window.scrollY;
      const pageHeight = document.documentElement.scrollHeight - window.innerHeight;
      const progress = (scrollPos / pageHeight) * 100;

      if (progress === 0) {

        setLogoSrc(logo1);

        setNumber(1);
        const bbButton = document.querySelector('#nav-btn');
        if (bbButton) bbButton.style.display = 'none';
        document.querySelectorAll('.ch-li, .ch-li1, .ch-li2, .ch-li3').forEach(item => {
          const bocw = document.querySelector('#t-b-n');
          bocw.style.borderColor = "#ffffff46";
          item.style.color = 'white'; 
          item.addEventListener('mouseenter', function() {
            this.style.borderColor = 'white';
          });
      
          item.addEventListener('mouseleave', function() {
              this.style.borderColor = ''; 
          });              
          const header = document.getElementById('header');     
          header.style.backgroundColor = 'transparent';
        });
        const tbn = document.querySelector('#t-b-n');
        if (tbn) tbn.style.color = '#ffff';
        const pbar = document.querySelector('.p-bar');
        if (pbar) pbar.style.backgroundColor = 'transparent';
      }

      const isScrollingUp = scrollPos < prevScrollPos;
      let margin = 0;
      if (isScrollingUp) {
        margin = 55;
      }
      const pbar = document.querySelector('.p-bar');
      if (pbar) pbar.style.marginTop = margin + 'px';
      const filled = document.querySelector('.filled');
      if (filled) filled.style.width = progress + '%';
      setPrevScrollPos(scrollPos);
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('scroll', handleProgress);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('scroll', handleProgress);
    };
  }, [lastScrollTop, prevScrollPos]);

  useEffect(() => {
    const toggleButton = document.querySelector('#t-b-n');
    if (toggleButton) {
      toggleButton.style.color = toggleIconColor;
    }
  }, [toggleIconColor]);

  const toggleMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
    if (number === 1) {
      setToggleIconColor(toggleIconColor === 'white' ? '#262626' : 'white');
    }
  };


  const closeMenu = () => {
    setIsMobileMenuOpen(false);
  };

  window.onscroll = () => {
    closeMenu();
  };


  const [isExpanded, setIsExpanded] = useState(false);
  const [isContrast, setIsContrast] = useState(false);

  const toggleExpand = () => {    
    setIsExpanded(!isExpanded);
  };



  return (
    <header id="header">
      <div className="container">
        <div className="n-navbar">
            <div id="logo">
            <HashLink smooth to="/en-pk/#" className='logow'><img src={logoSrc} alt="" title="" id="logoimage" /></HashLink>
            </div>
          
          <ul className="n-links">
            <li><HashLink smooth to={"/en/company-overview/#"} className="ch-li">About</HashLink></li>
            <li><HashLink smooth to={"/careers/#"} className="ch-li1">Careers</HashLink></li>
            <li><HashLink smooth to={"/source/#"} className="ch-li3">Insights</HashLink></li>
            <li><HashLink smooth to={"/en/legal/terms-of-use/#"} className="ch-li3">Legal</HashLink></li>
            
          </ul>

    
          <div className='lo-t'>

 
            <div className="toogle-btn" id="t-b-n" onClick={toggleMenu}>
              {isMobileMenuOpen ? <IoIosClose /> : <IoMenuOutline />}
            </div>
          </div>
          
        </div>
      </div>

      <div className={`n-m-menu ${isMobileMenuOpen ? 'open' : ''}`}>
      <div id="mobilelogo">
        <div className="container">
          <div id="mlogo">
            <img src={logo2} alt="" title="" id="mobilelogoimage" />
          </div>
        </div>
      </div>




        <ul className="ul-links-a">

          <li><HashLink smooth to="/en/company-overview/#" onClick={closeMenu}>About Haantlay</HashLink></li>
          <li><HashLink smooth to="/en/company-overview/#" onClick={closeMenu}>Who We Are</HashLink></li>
          <li><HashLink smooth to="/careers/#" onClick={closeMenu}>Careers</HashLink></li>
          <li><HashLink smooth to="/source/#" onClick={closeMenu}>Company News</HashLink></li>
          <li><HashLink smooth to="/en/legal/terms-of-use/#" onClick={closeMenu}>Legal</HashLink></li>
        </ul>
        <div className='nav-blog'>

        </div>
        <HashLink smooth to="/contactus/" className="n-btn" onClick={closeMenu}>Get in touch</HashLink>

        
      </div>

      <div className="p-bar">
        <div className="filled"></div>
      </div>
    </header>
  );
}



export default Header;