
import React, { useState, useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';
import { Carousel } from 'react-responsive-carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import { TfiAngleRight, TfiAngleLeft } from 'react-icons/tfi';
import luxury from '../assests/img/vid-in.webm';
import plane from '../assests/img/plane.webm';
import joinvid from '../assests/img/join.webm';
import newsimg4 from '../assests/img/blogs/blog1.jpg'
import { BsArrowRight } from "react-icons/bs";


import AOS from 'aos';

const Home = () => {

  useEffect(() => {
    document.title = "Forging Tomorrow's Techscape Today! | Haantlay";
    AOS.init({
      duration: 1000, 
      easing: 'ease-out', 
    });
  }, []);
  

  return (
    <>
      <Carousel infiniteLoop autoPlay 
        showStatus={false} 
        showThumbs={false} 
        showIndicators={false}
        swipeable={false}
        interval={5000}
        showArrows={true}
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && (
            <button type="button" onClick={onClickHandler} title={label} className="arrow-prev ">
              <TfiAngleLeft />
            </button>
          )
        } renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext && (
            <button type="button" onClick={onClickHandler} title={label} className="arrow-next">
              <TfiAngleRight />
            </button>
          )
        }
      >
          <div key="video1">
            <section id="video-whole">
              <div id="video-intro">
                <div className="container">
                  <div className="video-container">
                    <video autoPlay muted loop id="background-video" preload="auto">
                      <source src={luxury} type="video/webm" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                  <div className="row">
                    <div className="col-md-12 video-text">
                      <h1>Luxury <br /> Through <br /> Technology</h1>
                      <p>Haantlay sets new digital standards, blending luxury and innovation.</p>
                      <HashLink smooth to={"/contactus/"} id="button-dark-bg" className='text-dicoration-none'>GET IN TOUCH</HashLink>  
                    </div>
                  </div>
                </div>
              </div>
                  <div className="container">
                    <div className="col-md-12 video-sec-text">
                      <h1>Luxury <br /> Through <br /> Technology</h1>
                      <p>Haantlay sets new digital standards, blending luxury and innovation.</p>
                      <HashLink smooth to={"/contactus/"} id="button-light-bg" className='text-dicoration-none'>GET IN TOUCH</HashLink>  
                    </div>
                  </div>
            </section>
          </div>
          <div key="video2">
            <section id="video-whole">
              <div id="video-intro">
                <div className="container">
                  <div className="video-container">
                    <video autoPlay muted loop id="background-video" className='bg-po-tl' preload="auto">
                      <source src={plane} type="video/webm" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                  <div className="row">
                    <div className="col-md-12 video-text">
                      <h1>From <br /> A Dream <br /> To Reality</h1>
                      <p>Haantlay - Forging Tomorrow's Techscape, Today!</p>
                      <HashLink smooth to={"/contactus/"} id="button-dark-bg" className='text-dicoration-none'>GET IN TOUCH</HashLink>                      
                    </div>
                  </div>
                </div>
              </div>
                  <div className="container">
                    <div className="col-md-12 video-sec-text">
                      <h1>From <br /> A Dream <br /> To Reality</h1>
                      <p>Haantlay - Forging Tomorrow's Techscape, Today!</p>
                      <HashLink smooth to={"/contactus/"} id="button-light-bg" className='text-dicoration-none'>GET IN TOUCH</HashLink>  
                    </div>
                  </div>
            </section>
          </div>

      </Carousel>
      <section id="trans">
        <div className="container ">
          <div className="row">
            <div className="col-lg-6 cont">
              <h1>Translating technology into a positive impact</h1>
              <p className="p-hp">Our approach allows us to deliver exceptional experiences that drive growth and success for all stakeholders. Let’s rise to new heights with the power of digital transformation.</p>
            </div>
            <div className="col-lg-6  num">
              <div className="row che-num">
                <div className="col-md-6">
                  <span className="counter" data-target = "274">274+</span>
                  <p className='first'>Strategic Partnerships</p>
                </div>
                <div className="col-md-6">
                  <span className="counter" data-target = "421">421+</span>
                  <p className='first'>Cutting-Edge Solutions</p>
                </div>
                <div className="col-md-6">
                  <span className="counter" data-target = "1364">1364+</span>
                  <p>Continuous 24/7 Support</p></div>
                <div className="col-md-6">
                  <span className="counter" data-target = "18">18+</span>
                  <p>Devoted Team</p></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="join-us">
        <div className="container con-ab">
          <div className="video-container">
            <video autoPlay muted loop id="background-video" poster="img/buil.jpg">
              <source src={joinvid} type="video/webm" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div>
            <h1>Your next starts right here</h1>
          </div>
          <HashLink smooth to={"/careers/"} >JOIN US</HashLink>
        </div>
      </section>
      {/* <section id='company-news'>
        <div className="container-sm">
          <div className="row center">
              <div className="col-lg-6">
                <h1>Latest <br /> Company News</h1>
                <HashLink smooth to={"/contactus/"} className='news-link'>Explore more</HashLink><BsArrowRight/>
              </div>
                <div className="col-lg-3 col-md-6 col-sm-6 card-hide">
                  <div className="news-card">
                    <img src={newsimg4} className="img-fluid p-0 b-img ct" alt="Blog" />
                    <h2>Achievement</h2>  
                    <h1>Haantlay secures 2nd position in IEEE Expo</h1>  
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 card-hide">
                  <div className="news-card">
                    <img src={newsimg4} className="img-fluid p-0 b-img center-top" alt="Blog" />
                    <h2>Achievement</h2>  
                    <h1>Haantlay secures 2nd position in IEEE Expo</h1>  
                  </div>
                </div>
              <div className="card-scroll col-lg-6">
                <div className="">
                  <div className="news-card">
                    <img src={newsimg4} className="img-fluid p-0 b-img ct" alt="Blog" />
                    <h2>Achievement</h2>  
                    <h1>Haantlay secures 2nd position in IEEE Expo</h1>  
                  </div>
                </div>
                <div className="">
                  <div className="news-card">
                    <img src={newsimg4} className="img-fluid p-0 b-img center-top" alt="Blog" />
                    <h2>Achievement</h2>  
                    <h1>Haantlay secures 2nd position in IEEE Expo</h1>  
                  </div>
                </div>
              </div>
          </div>
        </div>
      </section> */}
      <section id="help">
        <div className="container">
          <h1>How can we help you?</h1>
          <p>Are you ready to push boundaries and explore new frontiers of innovation?</p>          
          <HashLink smooth to={"/contactus/#"} id="help-btn">Let's Work Together</HashLink>
        </div>
      </section>
    </>
  );
};

export default Home;


