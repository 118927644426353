import React from 'react';


import { FaFacebookF } from "react-icons/fa6";
import { GrLinkedin } from "react-icons/gr";
import { BsTwitterX } from "react-icons/bs";
import { SiInstagram } from "react-icons/si";
import { HashLink } from 'react-router-hash-link';

import { BsArrowUpShort } from "react-icons/bs";

import Ccookies from './cookies-check'

function Footer() {
  return (
    <>
      <Ccookies />
      <footer id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-md-6 fbbt order1">
                <p>Follow Haantlay</p>
                <div className="social-links-fs">
                  <a href="https://www.facebook.com/profile.php?id=100095130312866&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer" className="facebook"><FaFacebookF /></a>
                  <a href="https://x.com/haantlay?t=7THwqmT5whC1-Z82gw9e1w&s=09" target="_blank" rel="noopener noreferrer" className="twitter"><BsTwitterX /></a>
                  <a href="https://www.linkedin.com/company/haantlay/" target="_blank" rel="noopener noreferrer" className="linkedin"><GrLinkedin /></a>
                  {/* <a href="https://instagram.com/haantlay?igshid=OGQ5ZDc2ODk2ZA==" target="_blank" rel="noopener noreferrer" className="instagram"><SiInstagram /></a> */}
                </div>
              </div>
              <div className="col-md-6 fbt order2">
                <HashLink smooth to='#' className='text-dicoration-none'>
                  <div className="btp">
                    <BsArrowUpShort />
                    <p>Back To Top</p>
                  </div>
                </HashLink>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-links">
          <div className="container">
            <div className="row">
              <div className="col-md-3 col-sm-6 footer-contact">
                <p>Quick Links</p>
                <div className="footer-links-margin">
                  <HashLink smooth to={"/en-pk/#"} className="links" >Haantlay</HashLink>
                </div>
                <div className="footer-links-margin">
                  <HashLink smooth to={"/en/company-overview#"} className="links" >Discover Haantlay</HashLink>
                </div>
                <div className="footer-links-margin">
                  <HashLink smooth to={"/en/company-overview#our-philosopy"} className="links" >Our Philosopy</HashLink>
                </div>
                <div className="footer-links-margin">
                  <HashLink smooth to={"/careers#join-haantlay"} className="links" >Join Us</HashLink>
                </div>
                <div className="footer-links-margin">
                  <HashLink smooth to={"/contactus#"} className="links" >Get In Touch</HashLink>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 footer-contact cufts">
                <p>Company</p>
                <div className="footer-links-margin">
                  <HashLink smooth to={"/en/company-overview#"} className="links" >About</HashLink>
                </div>
                <div className="footer-links-margin">
                  <HashLink smooth to={"/en/company-overview/#"} className="links" >Who We Are</HashLink>
                </div>
                <div className="footer-links-margin">
                  <HashLink smooth to={"/en/company-overview/#haantlays-way"} className="links" >The Haantlay's Way</HashLink>
                </div>
                <div className="footer-links-margin">
                  <HashLink smooth to={"/source/#news"} className="links" >Company News</HashLink>
                </div>
                <div className="footer-links-margin">
                  <HashLink smooth to={"/en/legal/terms-of-use/#terms-of-use"} className="links" >Legal</HashLink>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 footer-contact">
                <p>Careers</p>
                <div className="footer-links-margin">
                  <HashLink smooth to={"/careers#join-haantlay"} className="links" >Join Us</HashLink>
                </div>
                <div className="footer-links-margin">
                  <HashLink smooth to={"/careers/#people-at-haantlay"} className="links" >People At Haantlay</HashLink>
                </div>
                <div className="footer-links-margin">
                  <HashLink smooth to={"/careers/#working-at-haantlay"} className="links" >Working At Haantlay</HashLink>
                </div>
                <div className="footer-links-margin">
                  <HashLink smooth to={"/careers/#our-hiring-process"} className="links" >Our Hiring Process</HashLink>
                </div>
                {/* <div className="footer-links-margin">                
                    <HashLink smooth to={"https://www.portfolio.haantlay.com/"} className="links" >Create a Portfolio</HashLink>
                  </div> */}
              </div>
              <div className="col-md-3 col-sm-6 footer-contact cufts">
                <p>We Respect</p>
                <div className="footer-links-margin">
                  <HashLink smooth to={"/en/privacystatement#"} className="links" >Your Privacy and Rights</HashLink>
                </div>
                <div className="footer-links-margin">
                  <HashLink smooth to={"/en/privacystatement#"} className="links" >Your Data and Security</HashLink>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <div className="container footer-bootom-con">
            <div className="links-container">
              <HashLink smooth to={"/contactus/#"} className="links">
                Contact Haantlay
              </HashLink>
              <HashLink smooth to={"/en/legal/terms-of-use/#"} className="links">
                Terms of use
              </HashLink>
              <HashLink smooth to={"/source/#news"} className="links">
                Inside Haantlay
              </HashLink>
            </div>
            <p>© Haantlay 2024</p>
          </div>
          <div className="container footer-bootom-con2">
            <div className="links-container2">
              <HashLink smooth to={"/contactus/"} className="links">
                Contact Haantlay
              </HashLink>
              <HashLink smooth to={"/en/legal/terms-of-use/#terms-of-use"} className="links">
                Terms of use
              </HashLink>
              <HashLink smooth to={"/source/#news"} className="links">
                Inside Haantlay
              </HashLink>
              <p>© Haantlay 2024</p>

            </div>
          </div>
        </div>


      </footer>
    </>
  );
}

export default Footer;
